import { Context, createContext, useEffect, useState } from "react";

import {
  fetchAndActivate,
  getRemoteConfig,
  getAll,
} from "firebase/remote-config";

import { firebaseApp } from "@logics/utils/firebase";
import { isCurrentAppVersionGreaterOrEqual } from "@logics/utils/appVersionUtils";

export const RemoteConfigContext = createContext({
  photo_permission: false,
  event_notice: false,
  project_feature: false,
  share_method: false,
  host_section: false,
  stay_introduction: false,
});

export type RemoteConfigType =
  typeof RemoteConfigContext extends Context<infer T> ? T : never;

const RemoteConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [flags, setFlags] = useState<RemoteConfigType>({
    photo_permission: false,
    event_notice: false,
    project_feature: false,
    share_method: false,
    host_section: false,
    stay_introduction: false,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const remoteConfig = getRemoteConfig(firebaseApp);
      remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
      fetchAndActivate(remoteConfig)
        .then(async () => {
          const newFlags = {
            ...flags,
          };

          await Promise.all(
            Object.entries(getAll(remoteConfig)).map(async ([key, config]) => {
              const value = config.asString();

              if (value === "true" || value === "false") {
                // NOTE: value가 "true" 또는 "false"일 경우 boolean으로 변환 (feature flag)
                newFlags[key] = value === "true";
              } else if (/^\d+\.\d+\.\d+$/.test(value)) {
                // NOTE: value가 "1.x.x" 일 경우 현재 앱 버전과 비교하여 boolean으로 변환 (version flag)
                newFlags[key] = await isCurrentAppVersionGreaterOrEqual(value);
              } else {
                // NOTE: 그 외의 경우 문자열 값 그대로 설정
                newFlags[key] = value;
              }
            }),
          );

          setFlags(newFlags);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <RemoteConfigContext.Provider value={flags}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export default RemoteConfigProvider;
