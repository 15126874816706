var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5a6c4ad0bf0c39a82e2b023a642bcb6cd304fd35"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const BOT_USER_AGENTS = [
  "Googlebot",
  "Bingbot",
  "Slurp",
  "DuckDuckBot",
  "Baiduspider",
  "YandexBot",
  "Sogou Spider",
  "Exabot",
  "Facebook External Hit",
  "Twitterbot",
  "FacebookBot",
  "Yeti",
  "HeadlessChrome", // NOTE: Bot은 아니고, Test 시 사용되는 Chrome의 Headless 모드.
];

function isUserAgentBot(userAgent) {
  return BOT_USER_AGENTS.some((bot) => userAgent?.includes(bot));
}

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  enabled: process.env.NEXT_PUBLIC_NODE_ENV !== "develop",
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    /Please ignore this error/i, // NOTE: post, edit event 할 때 뒤로가기 시 발생하는 에러 (웹에서만)
    "Can't find variable: msDiscoverChatAvailable", // NOTE: edge - sentry 호환 error
    /window.webkit.messageHandlers/, // NOTE: iOS error: webkit is a non-standard
    "ERROR: failed to load session replay browser plugin", // NOTE: amplitude session replay load error
  ],
  beforeSend(event) {
    const userAgent = event?.contexts?.request?.headers["user-agent"];

    // NOTE: Crawler의 경우 이벤트 무시
    if (isUserAgentBot(userAgent)) {
      return null;
    }

    return event;
  },
});
