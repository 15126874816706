import { css } from "styled-components";

import { BREAKPOINT } from "../variables";

/**
 * 미디어 쿼리를 위한 객체. 각 키는 다른 화면 크기의 디바이스를 대상으로 합니다.
 * - mobile: 모바일 디바이스 (최소 너비: 480px)
 * - pad: 태블릿 디바이스 (최소 너비: 768px)
 * - laptop: 랩탑 디바이스 (최소 너비: 1024px)
 * - desktop: 데스크탑 디바이스 (최소 너비: 1200px)
 *
 * @example
 *
 * ```
 * const MyElement = styled.div`
 * background-color: blue;
 *
 * ${mediaQueries.laptop} {
 *    background-color: red;
 * }
 * `;
 * ```
 *
 */
export const mediaQueries = {
  under1024: `@media (max-width: ${BREAKPOINT.LAPTOP})`,
  mobile: `@media (max-width: ${BREAKPOINT.MOBILE})`,
  pad: `@media (max-width: ${BREAKPOINT.PAD})`,
  laptop: `@media (max-width: ${BREAKPOINT.LAPTOP})`,
  desktop: `@media (max-width: ${BREAKPOINT.DESKTOP})`,
} as const;

/**
 * 지정된 줄 수에 맞추어 텍스트를 자르고 말줄임표를 적용하는 스타일을 반환합니다.
 * -webkit-line-clamp CSS 속성을 사용합니다.
 *
 * @param lines - 말줄임표를 적용할 최대 표시 줄 수. 기본값은 1줄. (optional)
 * @returns Styled-components의 `css` 헬퍼를 통해 생성된 스타일 규칙.
 * @example
 *
 * #### 1줄 말줄임
 * ```typescript
 * const MyText = styled.div`
 *  ${ellipsis()}; // or ${ellipsis(1)};
 * `;
 * ```
 *
 * #### 2줄 말줄임
 * ```typescript
 * const MyText = styled.div`
 *  ${ellipsis(2)};
 * `;
 * ```
 *
 */
export const ellipsis = (lines: number) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines || 1};

  overflow: hidden;

  text-overflow: ellipsis;
`;

/**
 * scrollbar를 제거하는 mixin.
 *
 * @example
 *
 * #### scrollbar UI 없애기
 * ```typescript
 * const MyParagraph = styled.div`
 *  ${removeScrollbar};
 * `;
 * ```
 *
 */
export const removeScrollbar = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

/**
 * 요소의 select를 막는 mixin.
 *
 * @example
 *
 * #### 특정 paragraph의 select 막기
 * ```typescript
 * const MyParagraph = styled.div`
 *  ${preventUserSelect};
 * `;
 * ```
 *
 */
export const preventUserSelect = css`
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`;

/**
 * flex column 레이아웃을 위한 mixin.
 * display: flex와 flex-direction: column을 함께 적용합니다.
 * 선택적으로 gap, align-items, justify-content를 지정할 수 있습니다.
 *
 * @param gap - 아이템 간의 간격 (px 단위, optional)
 * @param align - align-items 속성 (optional)
 * @param justify - justify-content 속성 (optional)
 * @returns Styled-components의 `css` 헬퍼를 통해 생성된 스타일 규칙
 * @example
 *
 * #### 기본 사용
 * ```typescript
 * const Column = styled.div`
 *   ${flexColumn()};
 * `;
 * ```
 *
 * #### gap 지정
 * ```typescript
 * const Column = styled.div`
 *   ${flexColumn(16)}; // gap: 16px
 * `;
 * ```
 *
 * #### 정렬 지정
 * ```typescript
 * const Column = styled.div`
 *   ${flexColumn(16, 'center', 'space-between')}; // gap: 16px, 가운데 정렬, 세로 방향 간격 균등 분배
 * `;
 * ```
 */
export const flexColumn = (
  gap?: number,
  align?: string,
  justify?: string,
) => css`
  display: flex;
  flex-direction: column;
  ${align && `align-items: ${align}`};
  ${justify && `justify-content: ${justify}`};
  ${gap !== undefined && `gap: ${gap}px`};
`;

/**
 * flex row 레이아웃을 위한 mixin.
 * display: flex를 기본으로 적용하고, 선택적으로 정렬과 gap을 지정할 수 있습니다.
 *
 * @param gap - 아이템 간의 간격 (px 단위, optional)
 * @param align - align-items 속성 (optional, 기본값: 'center')
 * @param justify - justify-content 속성 (optional)
 * @returns Styled-components의 `css` 헬퍼를 통해 생성된 스타일 규칙
 * @example
 *
 * #### 기본 사용
 * ```typescript
 * const Row = styled.div`
 *   ${flexRow()}; // 기본 center 정렬
 * `;
 * ```
 *
 * #### gap과 정렬 지정
 * ```typescript
 * const Row = styled.div`
 *   ${flexRow(16, 'flex-start', 'space-between')}; // gap: 16px, 좌측 상단 정렬, 양끝 정렬
 * `;
 * ```
 */
export const flexRow = (gap?: number, align?: string, justify?: string) => css`
  display: flex;
  ${align && `align-items: ${align}`};
  ${justify && `justify-content: ${justify}`};
  ${gap !== undefined && `gap: ${gap}px`};
`;
